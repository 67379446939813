import React from 'react';
import { navigate } from "gatsby";
import cx from 'classnames';
import buttonStyles from '../styles/components/buttons.module.scss';

import btnArrow from "../images/btn-arrows.svg";

export default function Button(props) {
    const { label, variant, to } = props;

    const iconNext = variant === 'next' && <img src={ btnArrow } alt="next" />;

    const linkTo = () => navigate(to);

    const isNext = () => {
        if (to) {
            linkTo();
        } else {
            alert('Вкладка буде закрита.');
            window.open("about:blank", "_self");
            window.close();
        }
    };

    return (
        <button
            { ...props }
            className={
                cx({
                    [buttonStyles.btnNext]: variant === 'next',
                }, buttonStyles.btn)
            }
            onClick={ isNext }
        >
            { label }
            { iconNext }
        </button>
    );
}