import React from "react";
import Layout from "../components/layout";
import Button from "../components/Button";
import indexStyles from "../styles/pages/index.module.scss";

import backgroundImage from "../images/bg-index.jpg";

export default function IndexPage() {

  return (
    <Layout background={backgroundImage}>
      <div className={indexStyles.age}>
        <span className={indexStyles.ageTitle}>Вам вже є 18 років?</span>
        <div className={indexStyles.ageBottom}>
          <div className={indexStyles.ageBottomItem}>
            <Button label="Так" to="/video" />
          </div>
          <div className={indexStyles.ageBottomItem}>
            <Button label="Ні" />
          </div>
        </div>
      </div>
    </Layout>
  );
}
