import React, { Fragment } from "react";
import Metadata from "./Metadata";
import layoutStyles from "../styles/components/layout.module.scss";

export default function Layout({ children, background }) {
  const bannerImageUrl = { backgroundImage: `url(${background})` };

  return(
    <Fragment>
      <Metadata />
      <div className={layoutStyles.wrapper} style={bannerImageUrl}>
        <div className={layoutStyles.container}>
            {children}
        </div>
      </div>
    </Fragment>
  );
}